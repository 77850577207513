<template>
  <div id="app">
    <router-view  v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}

</script>
<style lang="scss">
html, body, #app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.text-oneline{
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
}
</style>
