var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageMain" },
    [
      _c("tags-view"),
      _c(
        "transition",
        { attrs: { name: "fade-page", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            [!_vm.$route.meta.noCache ? _c("router-view") : _vm._e()],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-page", mode: "out-in" } },
        [_vm.$route.meta.noCache ? _c("router-view") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }