export const business = [
    // {
    //     "path": "/dashboard",
    //     "component": null,
    //     "id": "c99c9935-b412-a580-daf2-38ee0dd0d069",
    //     "name": "Dashboard",
    //     "redirect": null,
    //     "leaf": false,
    //     "menuShow": true,
    //     "parentId": "0",
    //     "iconCls": "el-icon-notebook-2",
    //     "children": [{
    //             "path": "/workspace",
    //             "component": null,
    //             "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
    //             "name": "工作台",
    //             "redirect": null,
    //             "leaf": true,
    //             "menuShow": true,
    //             "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
    //             "iconCls": null,
    //             "children": []
    //         },
    //         {
    //             "path": "/analysis",
    //             "component": null,
    //             "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
    //             "name": "统计分析",
    //             "redirect": null,
    //             "leaf": true,
    //             "menuShow": true,
    //             "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
    //             "iconCls": null,
    //             "children": []
    //         }
    //     ]
    // },
    {
        "path": "/member",
        "component": null,
        "id": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "name": "用户管理",
        "redirect": null,
        "leaf": false,
        "menuShow": true,
        "parentId": "0",
        "iconCls": "el-icon-notebook-2",
        "children": [{
                "path": "/student",
                "component": null,
                "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
                "name": "学员管理",
                "redirect": null,
                "leaf": true,
                "menuShow": true,
                "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
                "iconCls": null,
                "children": []
            },
            {
                "path": "/teacher",
                "component": null,
                "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
                "name": "员工管理",
                "redirect": null,
                "leaf": true,
                "menuShow": true,
                "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
                "iconCls": null,
                "children": []
            }
        ]
    },
    {
        "path": "/merchantClass",
        "component": null,
        "id": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "name": "班排管理",
        "redirect": null,
        "leaf": false,
        "menuShow": true,
        "parentId": "0",
        "iconCls": "el-icon-notebook-2",
        "children": [{
            "path": "/classmanage",
            "component": null,
            "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
            "name": "班级管理",
            "redirect": null,
            "leaf": true,
            "menuShow": true,
            "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
            "iconCls": null,
            "children": []
        }]
    }
]