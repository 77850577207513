import $axios from '@/utils/request'

// sso 用户登录
export function login (data) {
  const url = '/user/login'
  return $axios.fLoginPost(url, data)
}

// 获取登录用户按钮权限列表
export function getListPerm (data) {
  const url = '/menu/listPerm'
  return $axios.fLoginGet(url, data)
}

// 获取登录用户权限路由表
export function getListRouter (data) {
  const url = '/menu/listRouter'
  return $axios.fLoginGet(url, data)
}
