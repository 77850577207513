import Layout from '@/layout'
const myRouter = [
{
  path: '/my',
  name: 'My',
  component: Layout,
  redirect: '/myCenter/myManagemwnt',
  meta: {
      title: '我的中心',
      icon: 'el-icon-s-comment'
  },
  children: [{
          path: 'myManagemwnt',
          name: 'myManagemwnt',
          component: resolve => require(['@/views/my/myManagemwnt'], resolve),
          meta: { title: '管理', noCache: true }
      }
  ]
},
]
export default myRouter