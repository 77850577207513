import { business } from './business'
import { course } from './course'
import { teacher } from './teacher'
import { my } from './my'
const routerList = [
    ...course,
    ...business,
    ...teacher,
    ...my,
    {
        'path': '/home',
        'component': null,
        'id': 'c99c9935-b412-a580-daf2-38ee0dd0d070',
        'name': '首页',
        'redirect': null,
        'leaf': false,
        'menuShow': true,
        'parentId': '0',
        'iconCls': 'el-icon-s-home',
        'children': [],
        'meta': {
            'affix': true
        }
    }
]

export {
    routerList
}
