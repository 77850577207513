export const teacher = [{
    "path": "/teacher",
    "component": null,
    "id": "c99c9935-b412-a580-daf2-38ee0dd0d069",
    "name": "教学管理",
    "redirect": null,
    "leaf": false,
    "menuShow": true,
    "parentId": "0",
    "iconCls": "el-icon-notebook-2",
    "children": [{
        "path": "/prepareLesson",
        "component": null,
        "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
        "name": "备课中心",
        "redirect": null,
        "leaf": true,
        "menuShow": true,
        "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "iconCls": null,
        "children": []
    }, {
        "path": "/myLesson",
        "component": null,
        "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
        "name": "我的课表",
        "redirect": null,
        "leaf": true,
        "menuShow": true,
        "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "iconCls": null,
        "children": []
    }, {
        "path": "/correctWork",
        "component": null,
        "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
        "name": "作业修改",
        "redirect": null,
        "leaf": true,
        "menuShow": true,
        "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "iconCls": null,
        "children": []
    }]
}]