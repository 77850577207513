import Layout from '@/layout'

import educationRouter from './education'

const courseRouter = [{
        path: '/coursetime',
        name: 'Coursetime',
        component: Layout,
        redirect: '/coursetime/coursetime',
        children: [{
            path: 'coursetime',
            component: resolve => require(['@/views/course/courseTimes'], resolve),
            meta: { title: '课时统计', icon: 'el-icon-s-data', affix: true }
        }]
    },
    {
        path: '/coursecenter',
        name: 'Coursecenter',
        component: Layout,
        redirect: '/coursecenter/management',
        meta: {
            title: '课程中心',
            icon: 'el-icon-s-comment'
        },
        children: [{
                path: 'management',
                name: 'Management',
                component: resolve => require(['@/views/course/courseManagement'], resolve),
                meta: { title: '课程管理', noCache: true }
            },
            {
                path: 'student',
                name: 'Student',
                component: resolve => require(['@/views/course/courseStudent'], resolve),
                meta: { title: '学员课程' }
            },
            {
                path: 'spend',
                name: 'Spend',
                component: resolve => require(['@/views/course/courseSpend'], resolve),
                meta: { title: '学员课消' }
            }
        ]
    },
    ...educationRouter,
]
export default courseRouter