import { login, getListRouter } from '@/api/user/logo'
import { setToken, removeToken } from '@/utils/auth'
import router from '@/router'
import { routerList } from '@/settings/routerList'
const state = {
    passSafe:'N',
    name: localStorage.getItem('name') ? localStorage.getItem('name') : '', 
    token: localStorage.getItem('token_business') ? localStorage.getItem('token_business') : '', // token认证
    userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : '',
    // routerList: JSON.stringify(routerList) // localStorage.getItem('routerList') ? localStorage.getItem('routerList') : '[]'
    routerList: localStorage.getItem('routerList') ? localStorage.getItem('routerList') : '[]'
}
const mutations = {
    SET_PASSSAFE (state, val) {
        state.passSafe = val
    },
    SET_ROUTES(state, val) {
        // val = routerList //如果没有这句话就是走的缓存（后台配置的内容），这句话代表的是走自己设置的
        state.routerList = JSON.stringify(val)
        localStorage.setItem('routerList', JSON.stringify(val))
    },
    SET_TOKEN(state, val) {
        state.token = val
        setToken(val)
        // console.log(val);
        localStorage.setItem('token_business', val)
    },
    DEL_TOKEN(state) {
        state.token = ''
        removeToken()
        localStorage.removeItem('token_business')
        sessionStorage.removeItem('token_business')
        localStorage.removeItem('perms')
        localStorage.removeItem('routerList')
    },
    SET_NAME(state, val) {
        state.userName = val
    },
    DEL_NAME(state) {
        state.userName = ''
        localStorage.removeItem('userName')
    },
    SET_NAMETITLE(state, val) {
        state.name = val
    },
    DEL_NAMETITLE(state, val) {
        state.name = ''
        localStorage.removeItem('name')
    },
}
const actions = {
    setpassSafe({ commit }, formdatas) {
        commit('SET_PASSSAFE',formdatas)
    },
    // user login
    _login({ commit }, formdatas) {
        return new Promise((resolve, reject) => {
            login(formdatas)
                .then(res => {
                    if (res.state === 'success') {
                        commit('SET_TOKEN', res.body)
                        localStorage.setItem('pptToken', res.body)
                    }
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    loginOut({ commit }) {
        commit('DEL_TOKEN')
        commit('DEL_NAME')
        commit('DEL_NAMETITLE')
        router.push({
            path: '/login',
        })
    },
    tokenLose({ commit }) {
        commit('DEL_TOKEN')
        router.push({
            path: '/login',
            query: {
                tokenLose: 'true',
                redirect: router.currentRoute.fullPath
            }
        })
    },
    addRoutes({ commit }) {
        return new Promise((resolve, reject) => {
            localStorage.getItem('systemRole');
            getListRouter({
                    systemCode: localStorage.getItem('systemCode') || 'merchant',
                })
                .then(res => {
                    if (res.state === 'success') {
                        let resJson = res.body;
                        // console.log(resJson);
                        resJson.findIndex(item => {
                            // if (item.path === '/home') {
                            //     item.meta = { affix: true }
                            // } else
                            if (item.path === '/coursetime/coursetime') {
                                item.meta = { affix: true }
                            } else if (item.path === '/teacher') {
                                item.meta = { affix: true }
                            }
                        })
                        // resJson.push({
                        //     component: null,
                        //     iconCls: 'el-icon-s-tools',
                        //     id: '366b089a-3952-443b-9e8e-a04c1e5f732131cfg',
                        //     leaf: true,
                        //     menuShow: true,
                        //     name: '运营中心',
                        //     parentId: '0',
                        //     path: '/operation',
                        //     children: [{
                        //         component: null,
                        //         iconCls: 'el-icon-s-tools',
                        //         id:'123132131313131',
                        //         parentId: '366b089a-3952-443b-9e8e-a04c1e5f732131cfg',
                        //         leaf: true,
                        //         menuShow: true,
                        //         name: '竞赛管理',
                        //         path: '/contestManage',
                        //     }]
                        // })
                        commit('SET_ROUTES', resJson)
                    }
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}