import Layout from '@/layout'

const tableRouter =   {
  path: '/giveLesson',
  name: 'giveLesson',
  component: Layout,
  meta: {
    title: '授课管理',
    icon: 'el-icon-s-claim'
  },
  children: [
    {
      path: 'lessonManage',
      name: 'lessonManage',
      component: resolve => require(['@/views/senate/lesson-manage'], resolve),
      meta: { title: '我的排课', noCache: false }
    },
    {
      path: 'studentTemplate',
      name: 'studentTemplate',
      component: resolve => require(['@/views/senate/studentTemplatePane'], resolve),
      meta: { title: '学员报告', noCache: false }
    },
    {
      path: 'paper-read',
      name: 'PaperRead',
      component: resolve => require(['@/views/senate/paper-read'], resolve),
      meta: { title: '试卷批阅',noCache:true }
    },{
      path: 'teachingclass',
      name: 'teachingclass',
      // component: resolve => require(['@/views/giveLesson/class-manage'], resolve),
      component: resolve => require(['@/views/senate/class-manage'], resolve),
      meta: { title: '在教班级',noCache:true }
    },{
      path: 'assessment',
      name: 'assessment',
      component: resolve => require(['@/views/giveLesson/assessment'], resolve),
      meta: { title: '课堂评价',noCache:true }
    },{
      path: 'assessmentdetail',
      name: 'assessmentdetail',
      component: resolve => require(['@/views/giveLesson/assessmentdetail'], resolve),
      meta: { title: '评价详情',noCache:true }
    },
  ]
}

export default tableRouter
