import Layout from '@/layout'

const employeeRouter = [{
    path: '/employee',
    name: 'employee',
    component: Layout,
    redirect: '/employee/teacher',
    meta: {
        title: '员工设置',
        icon: 'el-icon-s-comment'
    },
    children: [{
        path: 'teacher',
        name: 'teacher',
        component: resolve => require(['@/views/member/teacher'], resolve),
        meta: { title: '员工管理', noCache: true }
    }, ]
}]
export default employeeRouter