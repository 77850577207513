import Vue from 'vue'
import { formatDate } from '@/utils/formatDate'

let weekDay = ['周末', '周一', '周二', '周三', '周四', '周五', '周六']

Vue.filter('dateFormat', (value) => {
  if (value == null) {
    return ''
  } else {
    value = value.substring(0, 19)
  }
  return value
})

Vue.filter('weekDay', (value) => {
  if (value == null) {
    return ''
  } else {
    value = weekDay[new Date(Date.parse(value)).getDay()]
  }
  return value
})

Vue.filter('formatDate', (value) => {
  if (value == null) {
    return ''
  } else {
    value = value.substring(0, 19)
    value = value.replace(/T/g, ' ')
  }
  return value
})

Vue.filter('uuidFormat', (value) => {
  if (value == null) {
    return ''
  } else {
    value = value.substring(0, 6)
  }
  return value
})

// 开始时间 no ~
Vue.filter('formatDateStartNo', (time) => {
  if (time == null || time === '') {
    return ''
  }
  let date = new Date(time)
  return formatDate(date, 'yyyy-MM-dd hh:mm')
})

// 开始时间
Vue.filter('formatDateStart', (time) => {
  if (time == null || time === '') {
    return ''
  }
  let date = new Date(time)
  return formatDate(date, 'yyyy-MM-dd hh:mm~')
})

// 结束时间
Vue.filter('formatDateEnd', (time) => {
  if (time == null || time === '') {
    return ''
  }
  var date = new Date(time)
  return formatDate(date, 'hh:mm')
})
