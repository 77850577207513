/**
 * 消息提示
 * 5秒后消失
 * @param msg 提示内容
 * @param type 0，成功，默认；1失败；2，警告
 */
window.$msg = function(msg = '操作成功', type = 0) {
    let map = {
        0: 'success',
        1: 'error',
        2: 'warning'
    }

    window.vm.$message({
        showClose: true,
        message: msg,
        type: map[type],
        duration: type === 1 ? 1000 * 5 : 1000 * 2
    })
}

window.$msgnew = function(msg = '操作成功', type = 0,zZindex) {
  let map = {
    0: 'success',
    1: 'error',
    2: 'warning'
  }

  window.vm.$message({
    customClass:zZindex,
    showClose: true,
    message: msg,
    type: map[type],
    duration: type === 1 ? 1000 * 5 : 1000 * 2
  })
}
/**
 * 确认框
 * @param msg 提示内容
 * @param fn 确认后的操作，默认不作操作
 */

window.$alert = function(msg = '操作成功', fn = () => {}) {
    window.vm.$alert(msg, '请注意', {
        confirmButtonText: '确定',
        callback: fn
    })
}

/**
 * 格式化货币
 * @param number
 * @param places
 * @param symbol
 * @param thousand
 * @param decimal
 * @returns {string}
 */
window.formatMoney = function(number, places, symbol, thousand, decimal) {
        number = number || 0
            // 保留的小位数 可以写成 formatMoney(542986,3) 后面的是保留的小位数，否则默 认保留两位
        places = !isNaN(places = Math.abs(places)) ? places : 2
            // symbol表示前面表示的标志是￥ 可以写成 formatMoney(542986,2,"$")
        symbol = symbol !== undefined ? symbol : '￥'
            // thousand表示每几位用,隔开,是货币标识
        thousand = thousand || ','
            // decimal表示小数点
        decimal = decimal || '.'
            // negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
            // i表示处理过的纯数字
        let negative
        negative = number < 0 ? '-' : ''
        let i
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
        let j
        j = (j = i.length) > 3 ? j % 3 : 0
        return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '￥1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
    }
    /**
     * 计算两个时间相差分钟数
     * @param sDate1 开始时间
     * @param sDate2 结束时间
     */
window.minutedifference = function(sDate1, sDate2) {
    var dateSpan, tempDate, iMinute
    sDate1 = Date.parse(sDate1)
    sDate2 = Date.parse(sDate2)
    dateSpan = sDate2 - sDate1
    dateSpan = Math.abs(dateSpan)
    iMinute = Math.floor(dateSpan / (60 * 1000))
        // console.log('iMinute', iMinute)
    return iMinute
}

/**
 * 发送get请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
 window.fGet = function(url, params) {
    if (url.indexOf('/student/question/getSubmitHistoryItem') === -1) {
        NProgress.start()
    }

    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                headers: {
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                    'appid': localStorage.getItem("appid"),
                },
            })
            .then(res => {
                if (url.indexOf('/student/question/getSubmitHistoryItem') === -1) {
                    NProgress.done()
                }
                resolve(res.data)
            })
            .catch(err => {
                if (url.indexOf('/student/question/getSubmitHistoryItem') === -1) {
                    NProgress.done()
                }
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('网络连接问题' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                            // $msg(err.response.statusText, 1)
                    }
                }
            })
    })
}
window.fGetFileText = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                    'appid': localStorage.getItem("appid"),
                },
                withCredentials: false,
            })
            .then(res => {
                NProgress.done()
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('网络连接问题' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                            // $msg(err.response.statusText, 1)
                    }
                }
            })
    })
}
/**
 * 登录接口的post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fLoginPost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                    'appid': '',
                },
                baseURL: process.env.NODE_ENV === 'development' ?
                    '/login' : process.env.VUE_APP_LOGIN_API_ADDRESS,
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                console.log(JSON.stringify(err))
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('请检查网络连接后重试' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                    }
                } else {
                    reject(err)
                }
            })
    })
}
window.fVideoGet = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
                data: params,
                headers: {
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                },
                baseURL: process.env.NODE_ENV === 'development' ?
                    '/video' : process.env.VUE_APP_VIDEO_API_ADDRESS,
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                if (res.code === 2) {
                    $alert('验证失效请重新登录')
                    sessionStorage.clear()
                    localStorage.clear()
                    clearCookie('token')
                    router.push('/login')
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                reject(err)
            })
    })
}


window.fVideoPost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .get(url, params, {
                headers: {
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                },
                baseURL: process.env.NODE_ENV === 'development' ?
                    '/video' : process.env.VUE_APP_VIDEO_API_ADDRESS,
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                if (res.code === 2) {
                    $alert('验证失效请重新登录')
                    sessionStorage.clear()
                    localStorage.clear()
                    clearCookie('token')
                    router.push('/login')
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                reject(err)
            })
    })
}
/**
 * 同步接口的post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fSyncPost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {

                baseURL: process.env.NODE_ENV === 'development' ?
                    '/sync' : process.env.VUE_APP_SYNC_API_ADDRESS,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                },
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                reject(err)
            })
    })
}


/**
 * 在线调试的post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fDebugPost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token")
                },
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                reject(err.data)
            })
    })
}

/**
 * 发送post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fPost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                    'appid': localStorage.getItem("appid"),
                },
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                if (res.data.errCode == '0101015') {
                    $alert('验证失效请重新登录')
                    sessionStorage.clear()
                    localStorage.clear()
                    clearCookie('token')
                    router.push('/login')
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('网络连接问题' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                            // $msg(err.response.statusText, 1)
                    }
                }
                // reject(err.data)
            })
    })
}
/**
 * 文件上传接口的post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fFilePost = function(url, params) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': localStorage.getItem("token"),
                    'token': localStorage.getItem("token"),
                    'appid': localStorage.getItem("appid"),
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                if (res.data.errCode == '0101015') {
                    $alert('验证失效请重新登录')
                    sessionStorage.clear()
                    localStorage.clear()
                    clearCookie('token')
                    router.push('/login')
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('网络连接问题' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                            // $msg(err.response.statusText, 1)
                    }
                }
                // reject(err.data)
            })
    })
}
/**
 * 发送post请求
 * @param url 请求地址（不包括服务器地址）
 * @param params 要携带的数据
 */
window.fThirdPost = function(url, params, thridObject) {
    NProgress.start()
    return new Promise((resolve, reject) => {
        axios
            .post(url, params, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'token': thridObject.token,
                    'appid': thridObject.appid
                },
                withCredentials: true
            })
            .then(res => {
                NProgress.done()
                if (res.data.errCode == '0101015') {
                    $alert('验证失效请重新登录')
                    return false
                }
                resolve(res.data)
            })
            .catch(err => {
                NProgress.done()
                if (err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            $msg('网络请求不存在' + err.response.status, 1)
                            break;
                        case 500:
                            $msg('网络连接问题' + err.response.status, 1)
                            break;
                        case 504:
                            $msg('网络请求超时' + err.response.status, 1)
                            break;
                        default:
                            console.log(JSON.stringify(err))
                            // $msg(err.response.statusText, 1)
                    }
                }
                // reject(err.data)
            })
    })
}
window.uuid = function() {
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    var uuid = s.join('')
    return uuid
  }