var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sideBar", attrs: { id: "domSideBar" } },
    [
      _c("div", { staticClass: "logo_container" }, [
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.opened,
              expression: "!opened",
            },
          ],
          attrs: { src: require("../../../assets/images/app_logo.png") },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.opened,
              expression: "opened",
            },
          ],
          attrs: { src: require("../../../assets/images/sm_logo.png") },
        }),
      ]),
      _c(
        "el-scrollbar",
        [
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "default-active": _vm.activeMenu,
                "background-color": "#3a3f51",
                "text-color": "#b5b6bd",
                "active-text-color": "#f5c319",
                mode: "vertical",
                "collapse-transition": false,
                collapse: _vm.opened,
              },
            },
            _vm._l(_vm.routes, function (value) {
              return _c("sidebar-item", {
                key: value.path,
                attrs: { item: value, basePath: value.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }