<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>个人中心</span>
    </div>
    <el-form
      :model="formData"
      ref="formData"
      label-width="100px"
      class="info-form"
    >
      <el-form-item label="用  户  名 ： ">
        <span>{{ formData.loginName }}</span>
      </el-form-item>
      <el-form-item label="姓　　名：">
        <span>{{ formData.name }}</span>
      </el-form-item>
      <el-form-item label="所属机构：">
        <span>{{ formData.merchantName }}</span>
      </el-form-item>
      <el-form-item label="用户角色：">
        <span>{{ formData.type }}</span>
      </el-form-item>
      <el-form-item label="密　　码：">
        <el-button @click="changePass" size="small" type="info"
          >修改密码</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 修改密码 -->
    <change-pass
      :show="changePassDialog"
      @close="changePassDialog = false"
      @savePass="savePass"
    ></change-pass>
  </el-dialog>
</template>

<script>
import ChangePass from "../ChangePass";
import { getMyInfo, userEditPwd,merchantPasswordCheck } from "@/api/user";

export default {
  components: {
    ChangePass,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields();
        }
      }
    },
  },
  data() {
    return {
      formData: {},
      changePassDialog: false,
    };
  },
  methods: {
    openDialog() {
      this.getUserInfo();
    },

    // 获取本人的用户信息
    async getUserInfo() {
      const res = await getMyInfo();
      this.formData = Object.assign({}, res.body);
    },

    // 关闭
    close() {
      this.$emit("close");
    },

    // 修改密码
    changePass() {
      this.changePassDialog = true;
    },

    // 保存密码
    async savePass(data) {
      let passData = {
        pwdOld: data.originalPass,
        pwdNew: data.newPass,
      };
      // 密码校验
      const formdate = new FormData();
      formdate.append('password',  data.newPass,)
      const res = await merchantPasswordCheck(formdate)
      if(!res.body){
        window.$msg('密码必须包含大写字母、小写字母、数字、特殊字符中的三种，请重新填写密码', 2)
        return
      }
      await userEditPwd({ ...passData });
      this.$store.dispatch("user/loginOut").then(() => {
        window.$msg("密码修改成功,请重新登录", 2);
        this.close();
        this.changePassDialog = false;
      });
      // fPost("/user/editPwd", { ...passData })
      //   .then(res => {
      //     if (res.state === "success") {
      //       $msg("密码修改成功");
      //       this.close();
      //       this.changePassDialog = false;
      //       clearCookie("token");
      //       clearCookie("token_business");
      //       localStorage.clear();
      //       this.$router.push("/admin/login");
      //     } else {
      //       $msg(res.errMsg, 2);
      //     }
      //   })
      //   .catch(err => {
      //     console.log("err", err);
      //     $msg("请求失败", 1);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
// .info-form /deep/ .el-form-item label:after {
  // content: " ";
  // display: inline-block;
  // width: 100%;
// }

.info-form /deep/ .el-form-item__label {
  text-align: justify;
}
/* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
// .info-form /deep/ .el-form-item.is-required .el-form-item__label:before {
//   content: none !important;
// }
</style>
