import Layout from '@/layout'

const trainRouter = [{
    path: '/trains',
    name: 'trains',
    component: Layout,
    redirect: '/trains/course-train',
    meta: {
        title: '培训中心',
        icon: 'el-icon-s-comment'
    },
    children: [{
            path: 'course-train',
            name: 'courseTrain',
            component: resolve => require(['@/viewsteacher/courseTrain'], resolve),
            meta: { title: '培训课程', noCache: true }
        },
        {
            path: 'course-in/:id',
            name: 'curriculumCatalog',
            component: resolve => require(['@/viewsteacher/courseTrain/curriculumCatalog'], resolve),
            meta: {
                title: '课程详情',
                noCache: true
            }
        },
        {
            path: 'course-live',
            name: 'courseLive',
            component: resolve => require(['@/viewsteacher/courseTrain/joinLive'], resolve),
            meta: {
                title: '进入课程',
                noCache: true
            }
        },
    ]
}]
export default trainRouter