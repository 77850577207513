import Vue from 'vue'

const getTimestamp = () => {
  return new Date()
}
Vue.config.errorHandler = (error) => {
  if (process.env.NODE_ENV === 'development') {
    console.log({error:1,time:getTimestamp(),content:error.stack})
    // window.onbeforeunload = () => false
  }else {
    return true;
  }
}
window.onerror = (message,source,lineno,colon,error) => {
  if (process.env.NODE_ENV === 'development') {
    console.log({error:2,time:getTimestamp(),message,source,lineno,colon,content:error.stack})
  }else {
    return true;
  }
}
window.addEventListener('error',event => {
  if (process.env.NODE_ENV === 'development') {
    console.log({error:3,time:getTimestamp(),content:event.error.stack})
  }else {
    return true;
  }
})