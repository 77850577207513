import axios from 'axios'
import store from '../store'
// import router from '../router'
import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { showLoading, hideLoading } from '@/utils/loading'
// import { MessageBox } from 'element-ui'

const $axios = axios.create({
    // 基础url，会在请求url中自动添加前置链接
    baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/api' : process.env.VUE_APP_API_ADDRESS
        // 设置超时时间
        // timeout: 80000
})
Vue.prototype.$http = axios // 并发请求
    // 在全局请求和响应拦截器中添加请求状态

// 请求拦截器
$axios.interceptors.request.use(
        config => {
            if(config.url !== '/edu/lesson/getLessonFinishedStatus') {
              NProgress.start()
              showLoading()
            }
            const token = store.getters.token
            if (token) {
                config.headers.Authorization = token // 请求头部添加token
                config.headers.token = token // 请求头部添加token
            }
            return config
        },
        error => {
            // 处理请求错误
            if(config.url !== '/edu/lesson/getLessonFinishedStatus') {
              NProgress.done()
              hideLoading()
            }
            console.log('debug', error)
            return Promise.reject(error)
        }
    )
    // 响应拦截器
$axios.interceptors.response.use(
    response => {
      if(response.url !== '/edu/lesson/getLessonFinishedStatus') {
        NProgress.done()
        hideLoading()
      }
            // const code = response.status // 获取响应状态码
        const res = response.data // 获取响应数据
        if (JSON.stringify(res).indexOf("punchout_form") != -1) {
            return Promise.resolve(res)
        }
        if (res.state === 'success') {
            return Promise.resolve(res)
        } else {
            if (res.errCode === '0101015') {
                store.dispatch('user/tokenLose').then(() => {
                        // location.reload()
                    })
                    // MessageBox.alert('验证失效请重新登录', '请注意', {
                    //   confirmButtonText: '确定',
                    //   cancelButtonText: '取消',
                    //   type: 'warning'
                    // }).then(() => {
                    //   store.dispatch('user/loginOut').then(() => {
                    //     location.reload()
                    //   })
                    // })
            } else if (res.errCode === '0201001' || res.errCode === "0211001" || res.errCode === "0101007" || res.errCode === "0101005") {
                return Promise.resolve(res)
            } else {
                if (res.errMsg.includes('该用户未设置钉钉用户ID')) {
                    window.$msg('该责任人未绑定钉钉账号', 1)
                } else {
                    window.$msg(res.errMsg, 1)
                }
                return Promise.reject(new Error(res.errMsg || 'Error'))
            }
        }
    },
    error => {
        NProgress.done()
        hideLoading()
        console.log(error)
        if (error.response) {
            switch (error.response.status) {
                // case 401:
                //   // 返回401 清除token信息并跳转到登陆页面
                //   store.commit('DEL_TOKEN')
                //   router.replace({
                //     path: '/login',
                //     query: {
                //       redirect: router.currentRoute.fullPath
                //     }
                //   })
                //   break
                case 404:
                    window.$msg('网络请求不存在' + error.response.status, 1)
                    break;
                case 500:
                    window.$msg('请求失败，请检查网络是否已连接' + error.response.status, 1)
                    break
                default:
                    window.$msg(error, 1)
            }
        } else {
            // 请求超时或者网络有问题
            if (error.message.includes('timeout')) {
                window.$msg('请求超时！请检查网络是否正常', 1)
            } else {
                window.$msg('请求失败，请检查网络是否已连接', 1)
            }
        }
        return Promise.reject(error)
    }
)

// get，post请求方法
export default {
    fGetFileText: function (url, params) {
        NProgress.start()
        return new Promise((resolve, reject) => {
          axios
            .get(url, {
              params: params,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': localStorage.getItem("token"),
                'token': localStorage.getItem("token")
              },
              withCredentials: false,
            })
            .then(res => {
              NProgress.done()
              resolve(res.data)
            })
            .catch(err => {
              NProgress.done()
              if (err.response.status) {
                switch (err.response.status) {
                  case 404:
                    $msg('网络请求不存在' + err.response.status, 1)
                    break;
                  case 500:
                    $msg('网络连接问题' + err.response.status, 1)
                    break;
                  case 504:
                    $msg('网络请求超时' + err.response.status, 1)
                    break;
                  default:
                    console.log(JSON.stringify(err))
                  // $msg(err.response.statusText, 1)
                }
              }
            })
        })
      },
      fFilePost: function (url, params) {
        NProgress.start()
        return new Promise((resolve, reject) => {
          axios
            .post(url, params, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': localStorage.getItem("token"),
                'token': localStorage.getItem("token"),
                'appid': localStorage.getItem("appid"),
                'Content-Type': 'multipart/form-data',
              },
              withCredentials: true
            })
            .then(res => {
              NProgress.done()
              if (res.data.errCode == '0101015') {
                $alert('验证失效请重新登录')
                sessionStorage.clear()
                localStorage.clear()
                clearCookie('token')
                router.push('/login')
                return false
              }
              resolve(res.data)
            })
            .catch(err => {
              NProgress.done()
              if (err.response.status) {
                switch (err.response.status) {
                  case 404:
                    $msg('网络请求不存在' + err.response.status, 1)
                    break;
                  case 500:
                    $msg('网络连接问题' + err.response.status, 1)
                    break;
                  case 504:
                    $msg('网络请求超时' + err.response.status, 1)
                    break;
                  default:
                    console.log(JSON.stringify(err))
                  // $msg(err.response.statusText, 1)
                }
              }
              // reject(err.data)
            })
        })
      },
    fPost(url, data) {
        return $axios({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
    },
    fGet(url, params) {
        return $axios({
            method: 'get',
            url,
            params
        })
    },
    fPut(url, params) {
        return $axios({
            method: 'put',
            url,
            params: params
        })
    },
    fDelete(url, params) {
        return $axios({
          method: 'delete',
          url,
          params
        })
    },
    fPptPost(url, data) {
        return $axios({
            method: 'post',
            url,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: data,
            baseURL: process.env.VUE_APP_PPT_ADDRESS,// (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? process.env.VUE_APP_PPT_ADDRESS : process.env.VUE_APP_PPT_ADDRESS
                // baseURL: process.env.VUE_APP_LOGIN_API_ADDRESS
        })
    },
    fLoginPost(url, data) {
        return $axios({
            method: 'post',
            url,
            data: data,
            baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/login' : process.env.VUE_APP_LOGIN_API_ADDRESS
                // baseURL: process.env.VUE_APP_LOGIN_API_ADDRESS
        })
    },
    fLoginGet(url, params) {
        return $axios({
            method: 'get',
            url,
            params,
            baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/login' : process.env.VUE_APP_LOGIN_API_ADDRESS
                // baseURL: process.env.VUE_APP_LOGIN_API_ADDRESS
        })
    },
    fUserInfoGet(url, params) {
        return $axios({
            method: 'get',
            url,
            params,
            baseURL: (process.env.NODE_ENV === 'development' && window.location.href.indexOf('localhost') !== -1) ? '/user' : process.env.VUE_APP_API_USERINFO
                // baseURL: process.env.VUE_APP_LOGIN_API_ADDRESS
        })
    }
}
