export const routerjson = [{
        path: '/login',
        name: 'Login',
        hidden: true,
        meta: {
            title: '登录页',
            noCache: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        meta: { title: '首页', icon: 'el-icon-s-home', affix: true }
    },
    {
        path: '/research',
        name: 'Research',
        redirect: '/research/curriculum-manage',
        meta: {
            title: '课程研发',
            icon: 'el-icon-wallet'
        },
        children: [{
                path: 'question-manage',
                name: 'QuestionManage',
                meta: { title: '问题管理' }
            },
            {
                path: 'question-records',
                name: 'QuestionRecords',
                // hidden: true,
                meta: { title: '提交记录' }
            },
            {
                path: 'quesbank-manage',
                name: 'QuesbankManage',
                meta: { title: '题库管理' }
            },
            {
                path: 'tag-manage',
                name: 'TsgManage',
                meta: { title: '标签管理' }
            },
            {
                path: 'curriculum-manage',
                name: 'CurriculumManage',
                meta: { title: '课程管理' }
            },
            {
                path: 'curriculum-category',
                name: 'CurriculumCategory',
                meta: { title: '课程类别' }
            },
            {
                path: 'testquestion-manage',
                name: 'TestquestionManage',
                meta: { title: '试题管理' }
            },
            {
                path: 'exam-manage',
                name: 'ExamManage',
                meta: { title: '试卷管理' }
            }
        ]
    },
    {
        path: '/senate',
        name: 'Senate',
        redirect: '/senate/class-manage',
        meta: {
            title: '教务管理',
            icon: 'el-icon-notebook-2'
        },
        children: [{
                path: 'class-manage',
                name: 'ClassManage',
                meta: { title: '班级管理' }
            },
            {
                path: 'class-quesbank',
                name: 'ClassQuesbank',
                meta: { title: '班级题库' }
            },
            {
                path: 'my-lessons',
                name: 'MyLessons',
                meta: { title: '我的排课' }
            },
            {
                path: 'lessons-audit',
                name: 'LessonsAudit',
                meta: { title: '补课审核' }
            }
        ]
    }
]