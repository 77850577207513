<template>
  <div class="sideItem" v-if="!item.hidden">
    <template
      v-if="
        hasOnlyChild(item.children, item) &&
          (!childItem.children || childItem.noChild)
      "
    >
      <page-link v-if="childItem.name" :to="resolvePath(childItem.path)">
        <el-menu-item :index="resolvePath(childItem.path)">
          <i :class="childItem.iconCls ? childItem.iconCls : ''"></i>
          <span slot="title">{{ childItem.name }}</span>
        </el-menu-item>
      </page-link>
    </template>
    <el-submenu v-else :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <i :class="item.iconCls ? item.iconCls : ''"></i>
        <span>{{ item.name }}</span>
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :basePath="resolvePath(child.path)"
      ></sidebar-item>
    </el-submenu>
  </div>
</template>

<script>
import PageLink from './Link'
import { isAbsolutePath } from '@/utils/validate'
import path from 'path'

export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      childItem: null
    }
  },
  methods: {
    hasOnlyChild (children = [], item) {
      // debugger
      let newChildren = children.filter(obj => {
        if (obj.hidden) {
          return false
        } else {
          return true
        }
      })
      // if (newChildren.length === 1 && !item.meta) {
      if (newChildren.length === 1 && !item.name) {
        this.childItem = newChildren[0]
        return true
      }
      if (newChildren.length === 0) {
        this.childItem = { ...item, path: '', noChild: true }
        return true
      }
      return false
    },
    resolvePath (router) {
      if (isAbsolutePath(router)) {
        return router
      }
      if (isAbsolutePath(this.basePath)) {
        return this.basePath
      }
      return path.join(this.basePath, router)
    }
  },
  components: {
    PageLink
  }
}
</script>
