import Layout from '@/layout'

const tableRouter =   {
  path: '/statistics',
  name: 'Statistics',
  component: Layout,
  meta: {
    title: '统计汇总',
    icon: 'el-icon-s-data'
  },
  children: [
    {
      path: 'hour-statistics',
      name: 'HourStatistics',
      component: resolve => require(['@/views/statistics/hour-statistics'], resolve),
      meta: { title: '课时统计' }
    },
    {
      path: 'hour-statistics-details',
      name: 'HourStatisticsDetails',
      component: resolve => require(['@/views/statistics/hour-statistics-details'], resolve),
      meta: { title: '课时详情', noCache:true}
    },
    {
      path: 'Classconsumdetails',
      name: 'Classconsumdetails',
      component: resolve => require(['@/views/statistics/Classconsumdetails'], resolve),
      meta: { title: '课消明细',noCache:true }
    },
    {
      path: 'student-hour',
      name: 'StudentHour',
      component: resolve => require(['@/views/statistics/student-hour'], resolve),
      meta: { title: '学员课时' }
    },
    {
      path: 'studentHour-detail',
      name: 'studentHourDetail',
      component: resolve => require(['@/views/statistics/studentHour-detail'], resolve),
      meta: { title: '课消明细', noCache: true }
    },
    {
      path: 'generation-hour',
      name: 'GenerationHour',
      component: resolve => require(['@/views/statistics/generation-hour'], resolve),
      meta: { title: '生成课时' }
    },
    {
      path: 'teacher-hour',
      name: 'TeacherHour',
      component: resolve => require(['@/views/statistics/teacher-hour'], resolve),
      meta: { title: '老师课时' }
    },
    {
      path: 'lessonStatList',
      name: 'lessonStatList',
      component: resolve => require(['@/views/statistics/lessonStatList'], resolve),
      meta: { title: '课时详情列表', noCache: true }
    },
    {
      path: 'teacher-salary',
      name: 'TeacherSalary',
      component: resolve => require(['@/views/statistics/teacher-salary'], resolve),
      meta: { title: '老师课酬' }
    },
    {
      path: 'cost-info',
      name: 'CostInfo',
      component: resolve => require(['@/views/statistics/cost-info'], resolve),
      meta: { title: '消费统计' }
    },
    {
      path: 'anomaly-class',
      name: 'AnomalyClass',
      component: resolve => require(['@/views/statistics/exceptionCase'], resolve),
      meta: { title: '异常事件' }
    },
    {
      path: 'dollars-list',
      name: 'dollarsList',
      component: resolve => require(['@/views/dollars/dollars-list'], resolve),
      meta: { title: '课酬统计'}
    },
    {
      path: 'dollars-campus',
      name: 'dollarsCampus',
      component: resolve => require(['@/views/dollars/dollars-campus'], resolve),
      meta: { title: '校区薪酬统计', noCache: true }
    },
    {
      path: 'campus-details',
      name: 'CampusDetails',
      component: resolve => require(['@/views/dollars/campus-details'], resolve),
      meta: { title: '', noCache: true }
    },
    {
      path: 'dollars-detail',
      name: 'DollarsDetail',
      component: resolve => require(['@/views/dollars/dollars-detail'], resolve),
      meta: { title: '统计详情', noCache: true }
    },
  ]
}

export default tableRouter
