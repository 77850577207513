export const course = [{
        'path': '/coursetime/coursetime',
        'component': null,
        'id': 'c99c9935-b412-a580-daf2-38ee0dd0d070',
        'name': '课时统计',
        'redirect': null,
        'leaf': false,
        'menuShow': true,
        'parentId': '0',
        'iconCls': 'el-icon-s-home',
        'children': [],
        'meta': {
            'affix': true
        },
    },
    {
        "path": "/coursecenter",
        "component": null,
        "id": "c99c9935-b412-a580-daf2-38ee0dd0d069",
        "name": "课程中心",
        "redirect": null,
        "leaf": false,
        "menuShow": true,
        "parentId": "0",
        "iconCls": "el-icon-notebook-2",
        "children": [{
                "path": "/management",
                "component": null,
                "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
                "name": "课程管理",
                "redirect": null,
                "leaf": true,
                "menuShow": true,
                "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
                "iconCls": null,
                "children": []
            },
            {
                "path": "/student",
                "component": null,
                "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
                "name": "学员课程",
                "redirect": null,
                "leaf": true,
                "menuShow": true,
                "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
                "iconCls": null,
                "children": []
            },
            {
                "path": "/spend",
                "component": null,
                "id": "86d07056-2ab3-4204-8ec7-112b760ffdb6",
                "name": "学员课消",
                "redirect": null,
                "leaf": true,
                "menuShow": true,
                "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d069",
                "iconCls": null,
                "children": []
            }
        ]
    }
]