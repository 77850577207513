export const my = [
{
  'path': '/myCenter',
  'component': null,
  'id': 'c99c9935-b412-a580-daf2-38ee0dd0d071',
  'name': '我的中心',
  "redirect": null,
  "leaf": false,
  "menuShow": true,
  "parentId": "0",
  "iconCls": "el-icon-notebook-2",
  "children": [{
          "path": "/myManagemwnt",
          "component": null,
          "id": "86d07056-2ab3-4204-8ec7-112b760ffdb1",
          "name": "管理",
          "redirect": null,
          "leaf": true,
          "menuShow": true,
          "parentId": "c99c9935-b412-a580-daf2-38ee0dd0d061",
          "iconCls": null,
          "children": []
      },
  ]
}
]