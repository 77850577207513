import Layout from '@/layout'

const operationRouter = [
    {
        path: '/operation',
        name: 'operation',
        component: Layout,
        redirect: '/operation/contestManage',
        meta: {
            title: '运营中心',
            icon: 'el-icon-s-comment'
        },
        children: [
            {
                path: 'contestManage',
                name: 'contestManage',
                component: resolve => require(['@/views/operation/contestManage'], resolve),
                meta: { title: '竞赛管理' , noCache: true}
          },
          {
            path: 'contest-detail',
            name: 'ContestDetail',
            component: resolve => require(['@/views/operation/contest-detail'], resolve),
            meta: { title: '竞赛详情', noCache: true }
            },
          {
            path: 'addContest',
            name: 'AddContest',
            component: resolve => require(['@/views/operation/newAddContest'], resolve),
            meta: { title: '新建竞赛', noCache: true }
            },
        ]
    }
]
export default operationRouter