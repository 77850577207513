import Layout from '@/layout'

const teacherRouter = [{
    path: '/teacher',
    name: 'teacher',
    component: Layout,
    redirect: '/teacher/prepareLesson',
    meta: {
        title: '教学管理',
        icon: 'el-icon-s-comment'
    },
    children: [{
            path: 'prepareLesson',
            name: 'prepareLesson',
            component: resolve => require(['@/viewsteacher/teacher/prepareLesson'], resolve),
            meta: { title: '备课中心', noCache: true }
        },
        {
            path: 'myLesson',
            name: 'myLesson',
            component: resolve => require(['@/viewsteacher/teacher/myLesson'], resolve),
            meta: { title: '我的课表', noCache: true }
        },
        {
            path: 'tryLesson',
            name: 'tryLesson',
            component: resolve => require(['@/viewsteacher/teacher/tryLesson'], resolve),
            meta: { title: '试听管理', noCache: true }
        },
        {
          path: 'tryLessonDetail',
          name: 'tryLessonDetail',
          component: resolve => require(['@/viewsteacher/teacher/tryLessonDetail'], resolve),
          meta: { title: '预约试听' }
        },
        {
            path: 'class-detail',
            name: 'classDetail',
            component: resolve => require(['@/viewsteacher/teacher/class-detail'], resolve),
            meta: { title: '班级详情', noCache: true }
        },

    ]
}]
export default teacherRouter
