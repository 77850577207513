<template>
  <div class="wrapper" :class="{ closeBar: opened }">
    <!-- <m-header></m-header> -->
    <div class="wrapper_con">
      <side-bar></side-bar>
      <page-main></page-main>
    </div>
  </div>
</template>

<script>
// import MHeader from './components/header'
import SideBar from './components/sideBar'
import PageMain from './components/PageMain'
import { mapGetters } from 'vuex'
export default {
  name: 'layout',
  created(){},
  computed: {
    ...mapGetters(['opened'])
  },
  components: {
    // MHeader,
    SideBar,
    PageMain
  }
}
</script>
