import $axios from '@/utils/request'

// 获取用户信息
export function getMyInfo(data) {
    const url = '/user/myInfo'
    return $axios.fUserInfoGet(url, data)
}

// 用户修改密码
export function userEditPwd(data) {
    const url = '/user/editPwd'
    return $axios.fLoginPost(url, data)
}

// 校验密码
// export function merchantPasswordCheck(data) {
//     const url = 'merchant/user/checkPass'
//     return $axios.fPost(url, data)
// }

export function merchantPasswordCheck(data) {
    const url = '/user/checkPass'
    return $axios.fLoginPost(url, data)
}
// 用户增加常用时间段
export function addUserPeriod(data) {
    const url = '/merchant/user/period/addUserPeriod'
    return $axios.fPost(url, data)
}

// 用户删除常用时间段
export function deleteUserPeriod(data) {
    const url = '/merchant/user/period/deleteUserPeriod'
    return $axios.fGet(url, data)
}

// 用户常用时间段列表
export function listTeacherLevel(data) {
    const url = '/merchant/user/period/listUserPeriodByUser'
    return $axios.fPost(url, data)
}

//视频管理模块 中根据id获取创建人姓名
export function getNameByUserId(data) {
    const url = '/merchant/user/getNameByUserId'
    return $axios.fGet(url, data)
}

// 学生列表 - 校区
export function getListStudentForCampus(data) {
    const url = '/merchant/user/listStudentForCampus'
    return $axios.fPost(url, data)
}