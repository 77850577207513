<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    :show-close='showClose'
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>修改密码</span>
      <span><span style="margin-left: 30px;color:#f7cf47;font-size:16px">*密码必须包含大写字母、小写字母、数字、特殊字符中的三种</span></span>
    </div>
    <el-form :model="formData" ref="formData" :rules="rules" label-width="100px">
      <el-form-item label="原密码：" prop="originalPass">
        <el-input v-model="formData.originalPass" show-password auto-complete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPass">
        <el-input v-model="formData.newPass" show-password auto-complete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="checkNewPass">
        <el-input v-model="formData.checkNewPass" show-password auto-complete="new-password"></el-input>
      </el-form-item>
      <el-form-item style="margin-top: 35px;">
        <el-button type="primary" @click="submitForm('formData')">确 定</el-button>
        <el-button @click="close" v-if="showClose">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    showClose:{
      default: true,
      type: Boolean
    },
    closeModal:{
      default: false,
      type: Boolean
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {
        originalPass: '',
        newPass: '',
        checkNewPass: ''
      },
      rules: {
        originalPass: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '新密码长度为6到40位', trigger: 'blur' }
        ],
        checkNewPass: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, message: '确认密码长度为6到40位', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 关闭
    close () {
      this.$emit('close')
    },

    // 保存
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.formData.newPass !== this.formData.checkNewPass) {
            window.$msg('两次密码输入不一致', 2)
          } else {
            this.$emit('savePass', { ...this.formData })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../style/dialog.scss";
</style>
