import Vue from 'vue'
import hljs from 'highlight.js' // 代码高亮插件

// 全局自定义指令
Vue.directive('focus', {
  inserted (el) {
    el.querySelector('input').focus()
  }
})

Vue.directive('preventReClick', {
  inserted (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
})

// 权限指令
Vue.directive('has', {
  inserted (el, binding) {
    if (!Vue.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el)
      el.style.display = 'none'
      // console.log('el', el)
    }
  }
})

// 权限检查方法
Vue.prototype.$_has = function (value) {
  let isExist = false
  let buttonpermsStr = localStorage.getItem('perms')
  if (buttonpermsStr === undefined || buttonpermsStr === null) {
    return false
  }
  let buttonperms = JSON.parse(buttonpermsStr)
  // console.log('buttonperms', buttonperms)
  for (let i = 0; i < buttonperms.length; i++) {
    if (buttonperms[i] === 'admin:all' || value.indexOf(buttonperms[i]) > -1) {
      isExist = true
      break
    } else {
    }
  }
  return isExist
}

// 代码高亮
Vue.directive('highlight', {
  deep: true,
  bind: function bind (el, binding) {
    // on first bind, highlight all targets
    var targets = el.querySelectorAll('code')
    var target
    var i
    setTimeout(() => {
      for (i = 0; i < targets.length; i += 1) {
        target = targets[i]
        if (typeof binding.value === 'string') {
          target.textContent = binding.value
        }
        hljs.highlightBlock(target)
      }
    }, 100)
  },
  componentUpdated: function componentUpdated (el, binding) {
    // after an update, re-fill the content and then highlight
    var targets = el.querySelectorAll('code')
    var target
    var i
    setTimeout(() => {
      for (i = 0; i < targets.length; i += 1) {
        target = targets[i]
        if (typeof binding.value === 'string') {
          target.textContent = binding.value
        }
        hljs.highlightBlock(target)
      }
    }, 100)
  }
})
