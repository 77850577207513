import Vue from 'vue'
import Router from 'vue-router'
import getTitle from '@/utils/getTitle'
import { getToken } from '@/utils/auth'
import Layout from '../layout'

Vue.use(Router)

/**
 * 路由相关属性说明
 * hidden: 当设置hidden为true时，意思不在sideBars侧边栏中显示
 * mete{
 * title: xxx,  设置sideBars侧边栏名称
 * icon: xxx,  设置ideBars侧边栏图标
 * noCache: true  当设置为true时不缓存该路由页面
 * }
 */

/* Router Modules */
import senateRouter from './modules/senate'
import researchRouter from './modules/research'
import giveLessonRouter from './modules/giveLesson'
import statisticsRouter from './modules/statistics'
// import systemRouter from './modules/system'
import businessRouter from './modules/business'
import teacherRouter from './modules/teacher'
import courseRouter from './modules/course'
import operationRouter from './modules/operation'

import myRouter from './modules/my'
import trainRouter from './modules/train'
import employeeRouter from './modules/employee'
// let routers = localStorage.getItem('routerList')

const routes = [{
        path: '/login',
        name: 'Login',
        component: resolve => require(['../views/public/user/login'], resolve),
        hidden: true,
        meta: {
            title: '登录页',
            noCache: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Layout,
        redirect: '/home',
        children: [{
            path: 'home',
            component: resolve => require(['@/views/home/homes'], resolve),
            meta: { title: '首页', icon: 'el-icon-s-data', affix: true }
        }]
    },
    {
        path: '/ppt/edits',
        component: () =>
            import ('@/views/PPT/index'),
        hidden: true,
        meta: { title: '编辑课件', noCache: true }
    },
    {
        path: '/ppt/looks',
        component: () =>
            import ('@/views/PPT/look'),
        hidden: true,
        meta: { title: '查看课件', noCache: true }
    },
    {
        path: '/ppt/lookg',
        component: () =>
            import ('@/views/PPT/lookg'),
        hidden: true,
        meta: { title: '查看课件', noCache: true }
    },
    {
        path: '*',
        component: resolve => require(['@/views/public/404/'], resolve),
        meta: {
            title: '404'
        }
    },
    ...courseRouter,
    ...operationRouter,
    ...trainRouter,
    ...businessRouter,
    ...employeeRouter,
    ...teacherRouter,
    ...myRouter,
    senateRouter,
    researchRouter,
    giveLessonRouter,
    statisticsRouter,
    // systemRouter,
    {
        path: '/senate/echartsview',
        name: 'echartsview',
        component: resolve => require(['@/views/senate/echartsview'], resolve),
        meta: { title: '图表', noCache: true }
    },
    {
        path: '/senate/record-list',
        name: 'RecordList',
        component: resolve => require(['@/components/senate/sub-record'], resolve),
        meta: { title: '提交记录', noCache: true }
    },
    {
        path: '/senate/paper-result',
        name: 'PaperResult',
        component: resolve => require(['@/views/senate/paper-result'], resolve),
        meta: { title: '试卷结果', noCache: true }
    },
    {
        path: '/research/question-details',
        name: 'QuestionDetails',
        component: resolve => require(['@/views/research/question-details'], resolve),
        meta: { title: '问题详情', noCache: true }
    },
    {
        path: '/research/question-subDetails',
        name: 'QuestionSubDetails',
        component: resolve => require(['@/views/research/question-subDetails'], resolve),
        meta: { title: '提交详情', noCache: true }
    },
    {
        path: '/research/editScratchMould',
        name: 'editScratchMould',
        component: resolve => require(['@/views/research/editScratchMould'], resolve),
        meta: { title: 'scratch', noCache: true }
    },
    {
        path: '/research/exam-details',
        name: 'examDetails',
        component: resolve => require(['@/views/research/exam-details'], resolve),
        meta: { title: '试卷详情', noCache: true }
    },
    {
        path: '/research/preview-task',
        name: 'PreviewTask',
        component: resolve => require(['@/views/research/preview-task'], resolve),
        meta: { title: '任务预览', noCache: true }
    },
    {
        path: '/teacher/prepareLesson-detail',
        name: 'prepareLesson',
        component: resolve => require(['@/viewsteacher/teacher/prepareLesson-detail'], resolve),
        meta: { title: '备课详情', noCache: true }
    },
    {
        path: '/teacher/giveLesson-detail',
        name: 'giveLesson',
        component: resolve => require(['@/viewsteacher/teacher/giveLesson-detail'], resolve),
        meta: { title: '授课详情', noCache: true }
    },
    {
        path: '/teacher/giveLesson-screen',
        name: 'giveLesson',
        component: resolve => require(['@/viewsteacher/teacher/giveLesson-screen'], resolve),
        meta: { title: '授课大屏', noCache: true }
    },
    {
        path: '/teacher/question-subDetails',
        name: 'QuestionSubDetails',
        component: resolve => require(['@/viewsteacher/teacher/question-subDetails'], resolve),
        meta: { title: '提交详情', noCache: true }
    },
    {
        path: '/teacher/paper-result',
        name: 'PaperResult',
        component: resolve => require(['@/viewsteacher/teacher/paper-result'], resolve),
        meta: { title: '试卷结果', noCache: true }
    },
    {
        path: '/teacher/exam-details',
        name: 'examDetails',
        component: resolve => require(['@/viewsteacher/teacher/exam-details'], resolve),
        meta: { title: '试卷详情', noCache: true }
    },
    {
        path: '/teacher/preview-pythonfile',
        name: 'previewPythonfile',
        component: resolve => require(['@/viewsteacher/teacher/preview-pythonfile'], resolve),
        meta: { title: '查看python提交', noCache: true }
    },
    {
        path: '/teacher/editScratchMould',
        name: 'editScratchMould',
        component: resolve => require(['@/viewsteacher/teacher/editScratchMould'], resolve),
        meta: { title: 'scratch', noCache: true }
    },
    {
        path: '/research/pdfpreview',
        name: 'PdfPreview',
        component: resolve => require(['@/views/research/pdfpreview'], resolve),
        meta: { title: 'pdf任务预览', noCache: true }
    },
    {
        path: '/senate/preview-pythonfile',
        name: 'previewPythonfile',
        component: resolve => require(['@/views/senate/preview-pythonfile'], resolve),
        meta: { title: '查看python提交', noCache: true }
    },
    {
        path: '/research/commit-record',
        name: 'commitRecord',
        component: resolve => require(['@/components/commitRecord'], resolve),
        meta: { title: '提交记录', noCache: true }
    },
    {
        path: '/research/preview-accessory',
        name: 'PreviewAccessory',
        component: resolve => require(['@/views/research/preview-accessory'], resolve),
        meta: { title: '课件预览', noCache: true }
    },
    {
        path: '/research/preview-video',
        name: 'PreviewVideo',
        component: resolve => require(['@/views/research/preview-video'], resolve),
        meta: { title: '视频预览', noCache: true }
    },
    {
        path: '/research/pvideos',
        name: 'pvideos',
        component: resolve => require(['@/views/detail/video-detail'], resolve),
        meta: { title: '视频预览', noCache: true }
    },
    {
        path: '/research/courses',
        name: 'pcourses',
        component: resolve => require(['@/views/detail/course-detail'], resolve),
        meta: { title: '课件预览', noCache: true }
    },
    {
        path: '/research/taskss',
        name: 'ptaskss',
        component: resolve => require(['@/views/detail/task-detail'], resolve),
        meta: { title: '任务预览', noCache: true }
    },
    {
        path: '/meredu/previewTask',
        name: 'mereduTask',
        component: resolve => require(['@/viewsteacher/courseTrain/meredu/index'], resolve),
        meta: { title: '任务', noCache: true }
    },

]

const router = new Router({
    mode: 'hash',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

// 解决Vue-Router升级导致的Uncaught (in promise)问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 导航守卫
router.beforeEach(async(to, from, next) => {
    document.title = getTitle(to.meta.title)
        // console.log(JSON.stringify(to.matched[0].path))
    if (to.path === '/login') {
        next()
    } else {
        if (getToken()) {
            if (to.meta.subTitle) { //判断是否有标题
                to.meta.title = to.meta.title + '_' + to.meta.subTitle;
            }
            if (to.meta.subTitle) {

            } else {
                next()
            }
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }
})
export default router