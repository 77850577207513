var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "sideItem" },
        [
          _vm.hasOnlyChild(_vm.item.children, _vm.item) &&
          (!_vm.childItem.children || _vm.childItem.noChild)
            ? [
                _vm.childItem.name
                  ? _c(
                      "page-link",
                      { attrs: { to: _vm.resolvePath(_vm.childItem.path) } },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: {
                              index: _vm.resolvePath(_vm.childItem.path),
                            },
                          },
                          [
                            _c("i", {
                              class: _vm.childItem.iconCls
                                ? _vm.childItem.iconCls
                                : "",
                            }),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(_vm.childItem.name))]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _c(
                "el-submenu",
                {
                  attrs: {
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", {
                      class: _vm.item.iconCls ? _vm.item.iconCls : "",
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.item.name))]),
                  ]),
                  _vm._l(_vm.item.children, function (child) {
                    return _c("sidebar-item", {
                      key: child.path,
                      attrs: {
                        item: child,
                        basePath: _vm.resolvePath(child.path),
                      },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }