var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper", class: { closeBar: _vm.opened } },
    [
      _c(
        "div",
        { staticClass: "wrapper_con" },
        [_c("side-bar"), _c("page-main")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }